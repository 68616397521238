import lazySizes from "lazysizes";
import "lazysizes/plugins/native-loading/ls.native-loading";

lazySizes.cfg.nativeLoading = {
  setLoadingAttribute: true,
  disableListeners: {
    scroll: true
  }
};

lazySizes.init();
